@media (max-width: 768px) {
    #slider h1, #slider h2 {
        font-size: 22px;
        font-weight: 600;
    }
    #slider .content {
        padding-top: 33%;
    }
    #slider .slick-dots li {
        margin: 0 0;
    }
    #slider .slick-dots li button:before {
        font-size: 8px;
    }
    .MuiListItemIcon-root {
        min-width: 42px !important;
    }
    .MuiPaper-root a {
        color: #000;
    }
    section {
        padding-top: 30px;
        padding-bottom: 15px;
    }
    section h2 {
        font-size: 21px;
        margin-bottom: 1.5rem;
    }
    .experience {
        margin-top: -10rem;
        margin-bottom: 4rem;
    }
    section#standards {
        padding-top: unset;
    }
    #standards .standards {
        margin-top: 1rem;
    }
    #products .MuiAccordion-root {
        box-shadow: unset;
    }
    #products .MuiAccordion-root:before {
        display: none;
    }
    .product {
        margin: unset;
        margin-top: 15px;
    }
    .product .info {
        padding: 15px 0 10px 0;
    }
    .product h5 {
        font-size: 13px;
    }
    #gallery .ReactGridGallery_tile {
        float: unset !important;
    }
    #gallery .ReactGridGallery_tile-viewport img {
        height: unset !important;
    }
    .css-19kzrtu {
        padding: 10px !important;
    }
    .contact-info, .team {
        padding-left: calc(var(--bs-gutter-x) * .5);
    }
    section#contact {
        padding-top: 25px;
    }
    .contact-form {
        padding: 22px;
        margin-top: 1rem;
    }
    .contact-form h4 {
        font-size: 18px;
    }
    .contact-form .form-control, .contact-form button {
        margin-top: 24px;
    }
    .contact-info {
        margin-top: 2rem !important;
    }
    .contact-info hr {
        width: unset;
    }
    #footer section {
        padding-top: 32px;
        background-image: unset;
    }
    #footer ul, #footer .contact p {
        margin-top: 1rem;
    }
}