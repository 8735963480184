body {
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
}

a {
    color: #0F5397;
    text-decoration: none;
}

.bg-primary {
    background-color: #0F5397 !important;
}

.menuicon svg {
    color: #FA961D;
}

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    transform: scaleX(-1);
}

span.subheading {
    font-size: 16px;
    font-weight: 600;
    color: #FF961D;
    text-transform: uppercase;
}

section h2 {
    margin-top: .5rem;
    margin-bottom: 2.5rem;
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    opacity: .8;
    position: relative;
}

section h2::after {
    margin-top: .7rem;
    display: block;
    position: absolute;
    width: 3.625rem;
    height: .2rem;
    content: '';
    background: #FF961D;
    border-radius: 10px;
}

section h2.type-2::after {
    left: 50%;
    margin-left: -26px;
}

section p {
    font-size: 14px;
    line-height: 30px;
    font-weight: normal;
    color: #000000;
    opacity: .8;
}

.mt-unset {
    margin-top: unset !important;
}

.btn-type-1 {
    padding: 16px 40px 16px 40px;
    color: #000;
    text-transform: uppercase;
    background-color: #FFFFFF;
    border-radius: 6px;
}

.btn-type-2 {
    padding: 12px 30px 12px 30px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    background-color: #FA961D;
    border-radius: 6px;
}

.btn-type-2:hover {
    color: #fff;
}

section {
    padding-top: 80px;
    padding-bottom: 80px;
}

#header {
    right: 0;
    left: 0;
    padding-top: 7px;
    position: absolute;
    z-index: 99;
}

#header .container {
    display: flex!important;
    align-items: center!important;
    justify-content: space-between!important;
}

#header-top {
    right: 0;
    left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
}

#header-top p {
    padding-left: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #FFA43B;
    margin-bottom: unset;
    text-transform: uppercase;
    opacity: 1;
}

#header-top p a {
    color: #000000;
    opacity: .8;
    text-transform: none;
}

#header-top svg {
    float: left;
    color: #FA961D;
    border: 1px solid #FA961D;
    border-radius: 55px;
    padding: 8px;
    box-sizing: unset !important;
    font-size: 19px !important;
}

/* Navbar */

.navbar ul {
    display: flex;
    list-style: none;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
}

.navbar ul li {
    padding: 10px 0 10px 30px;
}

.navbar ul li.active a::after {
    display: flex;
    content: "";
    border-bottom: 2px solid #ffffff;
    width: 28px;
    transition: 2s;
}

.navbar ul li a {
    size: 16px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}

.navbar ul li:first-child {
    padding-left: 0;
}

#slider {
    margin-top: 0;
}

#slider .slick-dots {
    bottom: 26px;
}

#slider .slick-dots li button:before {
    font-size: 13px;
    color: #FFFFFF;
}

#slider .content {
    padding-top: 16%;
    padding-bottom: 16%;
    min-height: 100%;
    background-size: cover;
    background-position: top;
    text-align: center;
}

#slider h1, #slider h2 {
    font-size: 50px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 3rem;
}

#slider h1 span, #slider h2 span {
    color: #FA961D;
}

.slider-1 {
    background: linear-gradient( 111deg, rgba(3, 83, 151, 75%), rgba(3, 83, 151, 75%)), url(../images/slider/slider-1.jpg);
}

.slider-2 {
    background: linear-gradient( 111deg, rgba(3, 83, 151, 75%), rgba(3, 83, 151, 75%)), url(../images/slider/slider-2.jpg);
}

.slider-3 {
    background: linear-gradient( 111deg, rgba(3, 83, 151, 75%), rgba(3, 83, 151, 75%)), url(../images/slider/slider-3.jpg);
}

.slider-4 {
    background: linear-gradient( 111deg, rgba(3, 83, 151, 75%), rgba(3, 83, 151, 75%)), url(../images/slider/slider-4.jpg);
}

.slider-5 {
    background: linear-gradient( 111deg, rgba(3, 83, 151, 75%), rgba(3, 83, 151, 75%)), url(../images/slider/slider-5.jpg);
}

.slider-6 {
    background: linear-gradient( 111deg, rgba(3, 83, 151, 75%), rgba(3, 83, 151, 75%)), url(../images/slider/slider-6.jpg);
}

.breadcrumbs {
    text-align: center;
    padding-top: 122px;
    padding-bottom: 60px;
    min-height: 100%;
    background-color: #0F5397;
}

.breadcrumbs h1 {
    font: normal normal bold 30px/46px Poppins;
    color: #FFFFFF;
    text-transform: capitalize;
}

.breadcrumbs ul {
    padding-left: unset;
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
}

.breadcrumbs ul li a {
    font: normal normal 600 14px/40px Poppins;
    color: #FF961D;
    text-transform: uppercase;
}

.breadcrumbs ul li a:hover {
    color: #0F5397 !important;
}

.breadcrumbs ul li+li {
    padding-left: 10px;
    font: normal normal 600 14px/40px Poppins;
    color: #FF961D;
    text-transform: uppercase;
}

.breadcrumbs ul li+li::before {
    display: inline-block;
    padding-right: 10px;
    content: "/";
    font: normal normal 600 14px/40px Poppins;
    color: #FF961D;
    text-transform: uppercase;
}

.experience {
    padding-left: 20px;
    padding-right: 20px;
    overflow: auto;
    width: fit-content;
    position: relative;
    margin-top: -12rem;
    margin-left: auto;
    margin-right: auto;
    background-color: #FF961D;
}

.experience h4 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    padding-top: 2rem;
    padding-left: 5rem;
    color: #FFFFFF;
}

.experience span {
    float: left;
    color: #FFFFFF;
    font-size: 78px;
    font-weight: normal;
}

section#standards {
    padding-bottom: unset;
}

#standards .row .col {
    display: flex;
}

#standards .row .col .standards {
    flex: 1;
}

#standards .standards {
    text-align: center;
    color: #fff;
    border-radius: 10px;
}

#standards .standards h4 {
    font-size: 21px;
    font-weight: 800;
    text-transform: uppercase;
    margin: 10px;
}

#standards .bottom {
    min-height: 32%;
    border-radius: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
}

#standards .standards h5 {
    font-size: 14px;
    font-weight: 500;
    margin: 10px;
    text-align: center;
}

#standards .standards.type-1 {
    background-color: #ba8dc6;
}

#standards .standards.type-1 .bottom {
    background-color: #8c3b97;
}

#standards .standards.type-2 {
    background-color: #acab97;
}

#standards .standards.type-2 .bottom {
    background-color: #7e7f6f;
}

#standards .standards.type-3 {
    background-color: #e58382;
}

#standards .standards.type-3 .bottom {
    background-color: #bf3c2a;
}

#standards .standards.type-4 {
    background-color: #F4AC35;
}

#standards .standards.type-4 .bottom {
    background-color: #EF7938;
}

#standards .standards.type-5 {
    background-color: #62C6BB;
}

#standards .standards.type-5 .bottom {
    background-color: #23A39D;
}

#standards .standards.type-6 {
    background-color: #BBC3C7;
}

#standards .standards.type-6 .bottom {
    background-color: #93A5A6;
}

#standards .standards.type-7 {
    background-color: #51B3D9;
}

#standards .standards.type-7 .bottom {
    background-color: #1F8CC6;
}

section#products {
    padding-top: 65px;
    padding-bottom: 45px;
}

#products .MuiTabs-flexContainer {
    justify-content: center;
}

#products .MuiTabs-indicator {
    display: none;
}

#products .PrivateTabIndicator-colorSecondary-8, #products .jss7 {
    background-color: unset !important;
}

#products .MuiButtonBase-root {
    text-transform: none;
    color: #000000;
    opacity: .8;
    background-color: transparent;
    border-radius: 55px;
    border: 1px solid #FA961D;
    margin-left: 7px;
    margin-right: 7px;
}

#products .MuiButtonBase-root.Mui-selected {
    opacity: 1;
    color: #FFFFFF;
    background-color: #FA961D;
}

#products .MuiTab-wrapper {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    text-transform: capitalize;
    opacity: .8;
}

#products .Mui-selected .MuiTab-wrapper {
    color: #F73935;
    opacity: 1;
}

#products .MuiTab-textColorInherit {
    opacity: unset;
}

.product {
    margin-top: 3rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #F9F9FD;
    transition: all .5s;
}

.product:hover {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 30px #3578C629;
    border-radius: 9px;
}

.product img:hover {
    cursor: zoom-in;
}

.product .info {
    padding: 25px;
}

.product h5 {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    text-transform: uppercase;
    opacity: 0.8;
}

.product p {
    margin-top: 1rem;
    font-size: 14px !important;
    line-height: unset !important;
    font-weight: 400;
    color: #000000;
    margin-bottom: unset;
    opacity: 0.8;
}

.supplier img {
    filter: grayscale(100%);
    opacity: 0.5;
    transition: .2s;
}

.supplier img:hover {
    filter: unset;
    opacity: 1;
}

section#enquiry {
    padding-top: 36px;
    padding-bottom: 36px;
}

#enquiry {
    background-color: #0F5397;
}

#enquiry h2, #enquiry p {
    color: #FFFFFF;
    opacity: 1;
}

.section-bg {
    background-color: #F9F9FD;
}

.contact-form {
    padding: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #ADBFD369;
}

section#contact {
    padding-top: 65px;
}

.contact-form h4 {
    margin-top: .5rem;
    margin-bottom: 2.5rem;
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    opacity: 0.8;
    position: relative;
}

.contact-form h4::after {
    margin-top: .7rem;
    display: block;
    position: absolute;
    width: 3.625rem;
    height: .2rem;
    content: '';
    background: #FF961D;
    border-radius: 10px;
}

.contact-form .react-tel-input .form-control {
    width: 100%;
    height: 45px;
    margin-top: 30px !important;
}

.contact-form .form-control, .contact-form button {
    margin-top: 35px;
}

.contact-form .MuiInputLabel-shrink {
    padding-left: 7px !important;
    padding-right: 7px !important;
    background-color: #FFF !important;
    color: #FF961D !important;
}

.contact-form .MuiButton-root {
    font-size: 14px;
    border: none;
    padding: 10px 20px!important;
    background-color: #FF961D;
    border-radius: 6px;
    color: #fff!important;
}

.contact-form .MuiButton-root:hover {
    background-color: transparent;
    color: #FF961D!important;
    border: 1px solid #FF961D;
}

.contact-form .css-1qxw4jt-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .contact-form .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #FF961D;
}

.contact-info, .team {
    padding-left: 3rem;
}

.contact-info svg {
    margin-top: 7px;
    float: left;
    color: #FA961D;
    border: 1px solid #FA961D;
    padding: 10px;
    box-sizing: unset !important;
    font-size: 25px !important;
}

.contact-info hr {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 2px;
    width: 60%;
    color: #FA961D;
    opacity: .5;
}

.contact-info p {
    padding-left: 4rem;
}

.contact-info p a {
    color: #000;
}

#footer {
    background-color: #0F5397;
}

#footer section {
    padding-top: 55px;
    padding-bottom: unset;
    background-image: url(../images/bg-footer.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
}

#footer p {
    font-size: 14px;
    line-height: 32px;
    font-weight: normal;
    color: #FFFFFF;
    opacity: 0.9;
}

#footer ul {
    margin-top: 2rem;
    padding-left: unset;
    list-style: none;
}

#footer ul li {
    padding-top: 5px;
    padding-bottom: 5px;
}

#footer h5 {
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
}

#footer h5:after {
    display: inline-block;
    content: "";
    border-bottom: 2px solid #FA961D;
    width: 22px;
    margin-left: 6px;
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
}

#footer h6 a {
    font-size: 14px;
    font-weight: normal;
    color: #FFFFFF;
    opacity: 1;
}

#footer h6 a:hover, #footer .contact a:hover {
    color: #FA961D;
}

#footer .contact svg {
    margin-top: .4rem;
    color: #fff;
    font-size: inherit;
    float: left;
}

#footer .contact p, #footer .contact a {
    margin-top: 2rem;
    padding-left: 30px;
    font-size: 14px;
    line-height: 26px;
    font-weight: normal;
    color: #FFFFFF;
    opacity: 1;
}

#footer .contact a {
    padding-left: 15px;
}

#footer hr {
    height: 2px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    color: #707070;
}

.copyright {
    text-align: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.copyright p, .copyright a {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #FCFCFC;
    opacity: 0.8;
}

.copyright a:hover {
    color: #ED7B0B;
}

.whatsapp {
    display: -webkit-flex;
    display: flex;
    position: fixed;
    z-index: 2;
    right: 30px;
    bottom: 8px;
    min-width: 60px;
    max-width: 95vw;
    margin-bottom: 30px;
    cursor: pointer;
    transition: background-color .2s linear;
    -webkit-tap-highlight-color: transparent;
}
.margin-0{
margin-bottom: unset !important;
}